import React from 'react'
import CabaretLayout from '../../../components/cabaretlayout'
import CabaretInfosNavigation from '../../../components/Infos/CabaretInfos/CabaretInfosNavigations'
import { StaticImage } from 'gatsby-plugin-image'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const CabaretInfosHebergements = () => (
    <CabaretLayout>
        <div className='cabaret-infos'>
            <CabaretInfosNavigation />

            <h1>Hébergement</h1>

            <div className='cabaret-infos-inner'>
                <h2>Auberge des balcons</h2>
                <h3>Blottie entre les murs de Maison Mère, un ancien couvent chargé d’histoire, l’Auberge des Balcons se démarque par son caractère authentique, son accueil sympathique et son ambiance vibrante.</h3>
                <h3>Le Bistro représente un lieu de diffusion culturelle porteur de vie et de beaux moments d'échanges autant pour les locaux que les visiteurs de passage!</h3>

                <Carousel infiniteLoop autoPlay interval={3000} showArrows={false} showIndicators={false} showStatus={false} showThumbs={false} className="my-4 !max-h-[800px]">
                    <div>
                        <StaticImage alt='Auberge des balcons - Image 1' className="!max-h-[800px]" imgClassName='rounded-3xl !max-h-[800px]' src="../../../images/cabaret/hebergement/caroussel1.jpg" />
                    </div>
                    <div>
                        <StaticImage alt='Auberge des balcons - Image 2' className="!max-h-[800px]" imgClassName='rounded-3xl !max-h-[800px]' src="../../../images/cabaret/hebergement/caroussel2.jpg" />
                    </div>
                    <div>
                        <StaticImage alt='Auberge des balcons - Image 3' className="!max-h-[800px]" imgClassName='rounded-3xl !max-h-[800px]' src="../../../images/cabaret/hebergement/caroussel3.jpg" />
                    </div>
                </Carousel>

                <h3>L'Auberge propose différentes options d'hébergement à partir de 25 $ (+ taxes) en dortoir et à partir de 64 $ (+ taxes) en chambre privée...</h3>

                <form action="https://aubergedesbalcons.com/" target='_blank'><button type='submit'>Réserver</button></form>
            </div>
        </div>
    </CabaretLayout>
)

export default CabaretInfosHebergements
